:root:root {
  --adm-color-primary: #fe9600;
}
html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Microsoft Yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  /* flex-shrink: 0; */
}

#root {
  width: 100vw;
  height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.relative {
  position: relative;
}

:root {
  --font-large: 20px;
  --font-medium: 16px;
  --font-small: 14px;
  --font-tiny: 12px;
  --text-color-primary: #ff9600;
  --text-color-secondary: #666666;
  --page-background: #f7f7f7;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
input {
  padding: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.not-scroll {
  overflow: hidden;
  position: fixed;
}
.link {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  outline: none;
}
.divider {
  background-color: #eeeeee;
  width: 100%;
  height: 1px;
  transform: scaleY(0.5);
  display: block;
}
.bg-white {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
}
.bg-gray {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  z-index: -1;
}
.button:hover {
  color: #ff9600;
}
.color-orange {
  color: #ff9600;
}
.color-gray666 {
  color: #666666;
}
